import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, IconButton, Link, makeStyles } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import InputIcon from '../../../input/inputIcon.component';
import CustomProgress from '../../../Progress/progress.component';
import StyledCheckbox from '../../../../components/checkForm/styledCheckbox.component';
import RcCalendar from '../../../../components/datepicker/rcdatepicker.component';

import useNearScreen from '../../../../hooks/useNearScreen';
import { useWindowSize } from '../../../../hooks/useResizeWindows';
import { format } from 'date-fns';
import PopoverModal from '../../../modal/popoverModal.component';
import { WarningAlert } from '../../../../helpers/alert.helpers';
import {
  cleanPayrollEmployeeHistoryAction,
  getHistoryPayrollByEmployeeAction,
} from '../../../../actions/payrollEmployeeAction';
import { PayrollHistoryCard } from '../../history/payrollHistoryCard';
import {
  getTypeLicenseAction,
  registerPayrollAdjustmentAction,
} from '../../../../actions/payrollAdjustmentAction';
import ResponseModal from '../../../modal/responseModal.component';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles({
  containerFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listDetails: {
    '& p': {
      margin: '1% 0 0 0',
    },
  },
  listSupport: {
    width: '100%',
    overflow: 'auto',
    paddingBottom: 5,
    marginTop: 10,
  },
  noResult: {
    padding: 10,
    textAlign: 'center',
    marginBottom: 100,
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
});

const initialFilter = {
  Empleadoid: 0,
  Offset: 0,
  Limit: 20,
  Filtro: null,
  Fechadesde: null,
  Fechahasta: null,
  Aceptado: false,
  Rechazado: false,
  Destacado: false,
  LoadMore: false,
  Editado: null,
};

export const DetailList = React.memo(({ empleadoId, editados }) => {
  const classes = useStyles();
  const externalRef = useRef();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(null);
  const [heightList, setHeightList] = useState(0);
  const [filter, setFilter] = useState('');
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [rangeDatesFilter, setRangeDatesFilter] = useState({
    dateFrom: format(new Date(), 'yyyy/MM/dd'),
    dateTo: format(new Date(), 'yyyy/MM/dd'),
  });
  const [modalData, setModalData] = React.useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
  });

  const { isNearScreen } = useNearScreen({
    externalRef: false ? null : externalRef,
    distance: '150px',
    once: false,
  });

  const { height } = useWindowSize();

  /**
   * Store Redux
   */
  const { payrollListEmployee, loading } = useSelector(
    (s) => s.payrollHistoryReducer,
  );

  /*Inicia componente */
  useEffect(() => {
    return () => {
      dispatch(cleanPayrollEmployeeHistoryAction());
    };
  }, [dispatch]);

  /**
   * Cambio identificador empleado
   */
  useEffect(() => {
    const data = {
      ...initialFilter,
      Empleadoid: empleadoId,
      LoadMore: false,
      Editado: editados ?? null,
    };
    setFilterData(data);
  }, [empleadoId, editados]);

  /**
   * Escucha intercepción de scroll, Ajusta filtro para nueva consulta
   */
  useEffect(() => {
    if (isNearScreen && !loading && payrollListEmployee.length > 0) {
      const totalregistros = payrollListEmployee[0].totalregistros;
      if (totalregistros !== payrollListEmployee.length) {
        setFilterData((prev) => ({
          ...prev,
          Offset: payrollListEmployee.length,
          LoadMore: true,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * Consulta listado soportes
   */
  useEffect(() => {
    if (!!filterData) {
      dispatch(getHistoryPayrollByEmployeeAction(filterData));
    }
  }, [dispatch, filterData]);

  /**
   * Calcula altura lista, respecto al tamaño de la pantalla
   */
  useEffect(() => {
    const newHeight = height ?? 0;
    const discount = newHeight * 0.409;
    setHeightList(newHeight - discount);
  }, [height]);

  /**
   * Sincroniza valor de filtro con state
   * @param {*} param0
   */
  const handleChangeFilter = ({ target }) => {
    setFilter(target.value);
    if (target.value.length === 0) {
      setFilterData((prev) => ({
        ...prev,
        Filtro: '',
        Offset: 0,
        LoadMore: false,
      }));
    }
  };

  /**
   * Filtra Listado por texto
   */
  const handleSearchByText = () => {
    setFilterData((prev) => ({
      ...prev,
      Filtro: filter,
      Offset: 0,
      LoadMore: false,
    }));
  };

  /**
   * Cierra modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Abre modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * Valida rango de fechas seleccionado,
   * setea información de filtro para nueva consulta
   */
  const handleFilterByDate = () => {
    const { dateFrom, dateTo } = rangeDatesFilter;

    if (dateFrom === '' || dateTo === '') {
      WarningAlert(
        'Upsss...!!',
        'Debes seleccionar un rango de fechas valido.',
      );
      return;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      WarningAlert(
        'Upsss...!!',
        'La fecha inicial no puede ser mayor a la final',
      );
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Fechadesde: dateFrom,
      Fechahasta: dateTo,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Sincroniza fecha inicial filtro con state
   * @param {*} date Fecha
   */
  const handleChangeInitialDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: date,
    }));
  };

  /**
   * Sincroniza fecha final filtro con state
   * @param {*} date Fecha
   */
  const handleChangeEndDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateTo: date,
    }));
  };

  /**
   * Limpia filtro rango fechas
   */
  const cleanFilterDate = () => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: format(new Date(), 'yyyy/MM/dd'),
      dateTo: format(new Date(), 'yyyy/MM/dd'),
    }));

    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Fechadesde: null,
      Fechahasta: null,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Lee y ajsuta filtro por estado.
   */
  const handlecheckFilters = ({ target }) => {
    const name = target.name;
    const checked = target.checked;

    if (name === 'chkAcepted') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Aceptado: checked,
        LoadMore: false,
      }));
    }

    if (name === 'chkRejected') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Rechazado: checked,
        LoadMore: false,
      }));
    }

    if (name === 'chkFavorites') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Destacado: checked,
        LoadMore: false,
      }));
    }
  };

  /**
   * Abre modal filtro estados
   */
  const handleOpenModalChecks = (e) => {
    setModalChecksAnchorEl(e.currentTarget);
  };

  /**
   * Cierra modal filtro estados
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Limpia filtro por estado
   */
  const cleanFilterStatus = () => {
    setFilterData((prevState) => ({
      ...prevState,
      Offset: 0,
      Aceptado: false,
      Rechazado: false,
      Destacado: false,
      LoadMore: false,
    }));
    handleCloseModalChecks();
  };

  const handleDeletePayroll = async (data, license) => {
    const response = await dispatch(
      getTypeLicenseAction(license, readResponseLicense, sendPayroll),
    );
    if (response !== 'no license') {
      await dispatch(
        registerPayrollAdjustmentAction(data, readResponseRegister),
      );
    }
  };

  const sendPayroll = () => {
    return;
  };

  const readResponseLicense = (response) => {
    setOpenModalDelete(false);
    setModalData({
      modalType: 'warning',
      title: 'Tu plan de Colfactura nómina venció',
      body: (
        <div>
          <p
            style={{
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {`Licencia Base de ${response?.claim} vencida.. La nomina Original fue transmitida por licencia de ${response?.claim}, es necesario renovarla para que puedas seguir emitiendo documentos.`}
          </p>
          <p
            style={{
              marginTop: 10,
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {'Si quieres comprar tu nuevo plan, haz clic en Comprar'}
          </p>
        </div>
      ),
      modalImage: 'warning',
      open: true,
      textButton: 'Comprar',
      closeElement: 'Skip',
      urlButton: 'https://colfactura.com/nomina-electronica/',
    });
  };

  /**
   * Lee respuesta transmision notas de ajuste
   * @param {*} data
   * @returns
   */
  const readResponseRegister = (data) => {
    setOpenModalDelete(false);
    if (
      (data?.httpCode === 200 || data?.httpCode === 201) &&
      data?.result?.codigoRespuesta === 'A10'
    ) {
      setModalData({
        type: 'success',
        title: 'Fenomenal!!!',
        subtitle: 'Has generado un nuevo documento',
        body: (
          <div>
            <div>{'Proceso Exitoso'}</div>
            <div className="fenomenalWhiteLittle">
              {
                'Información de nomina de ajuste tipo Eliminación registrada exitosamente. '
              }
              <span className="fenomenalBlueLittle">
                No. {data?.result?.numero ?? ''}
              </span>
            </div>
          </div>
        ),
        modalImage: 'success',
        open: true,
        closeElement: 'Saltar',
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    } else {
      //Leer Error
      let errorMessage = data?.result?.descripcionRespuesta ?? null;
      errorMessage = errorMessage ?? data?.data?.result?.descripcionRespuesta;

      setModalData({
        type: 'warning',
        title: 'Upss...!!!',
        subtitle: 'Ocurrió algo inesperado',
        body: (
          <div>
            {errorMessage ??
              'Error no identificado, por favor contacta al administrador.'}
          </div>
        ),
        modalImage: 'warning',
        open: true,
        closeElement: 'Cerrar',
        onCloseElement: 1,
        closeFromModalBody: false,
      });
    }
  };

  /**
   * Cierra Modal, Notificación codigo unico no valido
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision" />
            <div className="spacingInvoiceMini" />
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision" />
            <div className="spacingInvoiceMini" />
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateFrom}
              className={'colorCalendarRC'}
              id={'txtfechainicio'}
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateTo}
              className={'colorCalendarRC'}
              id={'txtfechafin'}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={handleFilterByDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={Boolean(filterData?.Aceptado)}
              onChange={handlecheckFilters}
              name="chkAcepted"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Aceptados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              onClick={() => handlecheckFilters}
              checked={Boolean(filterData?.Rechazado)}
              onChange={handlecheckFilters}
              name="chkRejected"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Rechazados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              onClick={() => handlecheckFilters}
              checked={Boolean(filterData?.Destacado)}
              onChange={handlecheckFilters}
              name="chkFavorites"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Destacados
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilterStatus}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Grid container style={{ padding: '0px 15px' }}>
        <Grid item lg={10} xs={10}>
          <InputIcon
            name={'txtFilter'}
            label={'Buscar'}
            maxLength={15}
            value={filter}
            onChange={handleChangeFilter}
            onSearch={handleSearchByText}
          />
        </Grid>
        <Grid item lg={2} xs={2}>
          <div className={classes.containerFilter}>
            <IconButton
              onClick={handleOpenModalDates}
              style={{ color: 'white' }}
            >
              <EventIcon />
            </IconButton>
            <IconButton onClick={handleOpenModalChecks}>
              <img alt={'Filtrar'} src={filterImage} />
            </IconButton>
          </div>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Grid container className={`${classes.listDetails} `}>
            <div className={classes.listSupport} style={{ height: heightList }}>
              {payrollListEmployee.map((item) => (
                <PayrollHistoryCard
                  key={item.detallenominaid}
                  {...item}
                  handleDeletePayroll={handleDeletePayroll}
                  openModalDelete={openModalDelete}
                  setOpenModalDelete={setOpenModalDelete}
                />
              ))}

              <div ref={externalRef} id="visor" />
              {loading && <CustomProgress size={25} />}
              {!loading && payrollListEmployee.length === 0 && (
                <div className={classes.noResult}>
                  'No se han encontrado resultados'
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <PopoverModal
        handleClose={handleCloseModalDates}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={''}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />
      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
    </>
  );
});
