export const TOKEN_PREFIX = '';
export const QUANTITY_DATA = '20'; //Debe ser 20
export const QUANTITY_DATA_CATEGORY = '15';
export const QUANTITY_DATA_DOCUMENT = '30'; //debe ser 30
export const SIZE_IMAGE = '1024';
export const SIZE_FILE_BULKLOAD = 50000;
export const EXPIRATION_DAY_BULKLOAD = 5;
export const QUANTITY_DATA_MULTIMEDIA = 30;
export const DIGITS_BEFORE_DECIMAL = 9;
export const DIGITS_AFTER_DECIMAL = 2;

export const API_ENDPOINT_ENTERPRISE = 'https://qanet.colfactura.com';
export const API_ENDPOINT_CONFIGURATION = 'https://qanet.colfactura.com';
export const API_ENDPOINT_EMPLOYEE = 'https://qanet.colfactura.com';
export const API_ENDPOINT_PAYROLL = 'https://qanet.colfactura.com';

export const API_ENDPOINT_SIGNALR = 'https://qanet.colfactura.com';

export const API_ENDPOINT_LICENCE = 'https://qacorelicence.gse.com.co';

export const API_ENDPOINT_IBUHOO =
  'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa';
export const URL_IMAGES = 'https://qanet.colfactura.com';
export const API_IBUHOO_IMAGEMANAGER = 'https://qanet.colfactura.com';
export const CHATBOT_WOLKVOX =
  'https://chat01.ipdialbox.com/chat/?prodId=Z3NlLjM0Mg==';
export const URL_SECURITY = 'https://qasecurity-nomina.colfactura.com/';
//qalicence-colnomina.colfactura.com/myplan
export const URL_LICENCE = 'https://qalicence-colnomina.colfactura.com'; //LICENCIA NOMINA
export const URL_STORE = 'https://colfactura.com/nomina-electronica-qa/'; //TIENDA NOMINA
export const CLAIM_BRANCH_OFFICES = 'COLFACTURA_SUCURSALES';
export const CLAIM_USERS = 'COLFACTURA_USUARIOS';
export const CLAIM_DOCUMENTS = 'COLFACTURA_PROCESAMIENTOFACTURAS';
export const API_ENDPOINT_DISCHARGE =
  'https://qacorelicence.gse.com.co';

export const API_ENDPOINT_CONSULT_DOCUMENT = 'https://qanet.colfactura.com';

export const DEFAULTRESPONSE = {
  noResponseFromApi: {
    statusCode: '500',
    statusMessage: 'No hay respuesta por parte del servidor.',
    result: '',
  },
  SuccessResponse: {
    statusCode: '200',
    statusMessage: 'Transacción exitosa',
    result: '',
  },
};

export function defaultHeaders() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
    },
  };
}

export const ROL_CNCOLAB = 'CNCOLAB';

// Maximum size of images in bytes
export const imageSize = 2097152;

// Size of the image cutter canvas
export const imageCutterSize = {
  width: 450,
  height: 450,
};

export const TIME_REFRESH_TOKEN = 20;

//Url Video Basic Data
export const URL_VIDEO_HELP =
  'https://www.youtube.com/watch?v=_7q8762SdXU&t=28s';
