export const GET_ERRORS = 'GET_ERRORS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOADING = 'LOADING';
export const SET_INDEX_SELECTED = 'SET_INDEX_SELECTED';

//Begin users
export const SAVE_BILLER = 'SAVE_BILLER';
export const INFO_BILLER = 'INFO_BILLER';
export const INFO_LICENSE_BILLER = 'INFO_LICENSE_BILLER';
export const UPDATE_BILLER = 'UPDATE_BILLER';
export const UPDATE_STATUS_BILLER = 'UPDATE_STATUS_BILLER';
export const GET_BILLERS = 'GET_BILLERS';
export const LOAD_BILLER = 'LOAD_BILLER';
export const LOADING_GET_BILLERS = 'LOADING_GET_BILLERS';
export const LOADING_SAVE_BILLER = 'LOADING_SAVE_BILLER';
export const SAVE_SELLER = 'SAVE_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const UPDATE_STATUS_SELLER = 'UPDATE_STATUS_SELLER';
export const GET_SELLERS = 'GET_SELLERS';
export const LOAD_SELLER = 'LOAD_SELLER';
export const LOADING_GET_SELLERS = 'LOADING_GET_SELLERS';
export const LOADING_SAVE_SELLER = 'LOADING_SAVE_SELLER';
export const DISABLED_ACTIONS_USER = 'DISABLED_ACTIONS_USER';

//Begin offices
export const CLEAN_RESPONSES = 'CLEAN_RESPONSES';
export const GET_BRANCH_OFFICES = 'GET_BRANCH_OFFICES';
export const GET_OFFICES = 'GET_OFFICES';
export const GET_COMPANY = 'GET_COMPANY';
export const LOAD_BRANCH_OFFICE = 'LOAD_BRANCH_OFFICE';
export const SAVE_BRANCH_OFFICE = 'SAVE_BRANCH_OFFICE';
export const INFO_SAVE_BRANCH_OFFICES = 'INFO_SAVE_BRANCH_OFFICES';
export const UPDATE_BRANCH_OFFICE = 'UPDATE_BRANCH_OFFICE';
export const UPDATE_STATE_BRANCH_OFFICE = 'UPDATE_STATE_BRANCH_OFFICE';
export const GET_FILTER_BRANCH_OFFICES = 'GET_FILTER_BRANCH_OFFICES';
export const STATUS_EDIT_BRANCH_OFFICE = 'STATUS_EDIT_BRANCH_OFFICE';
export const STATUS_STEP_BRANCH_OFFICE = 'STATUS_STEP_BRANCH_OFFICE';
export const UPDATE_PRINCIPAL_BRANCH = 'UPDATE_PRINCIPAL_BRANCH';
export const LOADING_SAVE_BRANCH = 'LOADING_SAVE_BRANCH';
export const LOADING_GET_BRANCH_OFFICES = 'LOADING_GET_BRANCH_OFFICES';
export const UPDATE_INITIAL_DATA = 'UPDATE_INITIAL_DATA';
export const UPDATE_DIAN_DATA = 'UPDATE_DIAN_DATA';
export const CLEAN_LOADED_OFFICE = 'CLEAN_LOADED_OFFICE';
export const LOADING_CHECK_CERTIFICATE = 'LOADING_CHECK_CERTIFICATE';

//Begin numeration
export const GET_NUMERATION = 'GET_NUMERATION';
export const GET_NUMERATIONS = 'GET_NUMERATIONS';
export const SAVE_NUMERATION = 'SAVE_NUMERATION';
export const UPDATE_NUMERATION = 'UPDATE_NUMERATION';
export const LOAD_NUMERATION = 'LOAD_NUMERATION';
export const STATUS_STEP_NUMERATION = 'STATUS_STEP_NUMERATION';
export const GET_FILTER_NUMERATIONS = 'GET_FILTER_NUMERATIONS';
export const SYNC_GET_NUMERATION = 'SYNC_GET_NUMERATION';
export const UPDATE_NUMERATION_STATE = 'UPDATE_NUMERATION_STATE';
export const START_SYNC_NUMERATION = 'START_SYNC_NUMERATION';
export const STATUS_SYNC_NUMERATION = 'STATUS_SYNC_NUMERATION';
export const LOADING_UPDATE_NUMERATION = 'LOADING_UPDATE_NUMERATION';
export const LOADING_SAVE_NUMERATION = 'LOADING_SAVE_NUMERATION';
export const LOADING_GET_NUMERATIONS = 'LOADING_GET_NUMERATIONS';

//Begin basicData
export const SAVE_BASIC_DATA = 'SAVE_BASIC_DATA';
export const GET_TYPE_DOCUMENT = 'GET_TYPE_DOCUMENT';
export const GET_REGIMEN = 'GET_REGIMEN';
export const STATUS_STEP_BASIC_DATA = 'STATUS_STEP_BASIC_DATA';
export const LOADING_SAVE_BASIC_DATA = 'LOADING_SAVE_BASIC_DATA';
export const LOADING_GET_BASIC_DATA = 'LOADING_GET_BASIC_DATA';
export const LOADING_SAVE_DIAN_DATA = 'LOADING_SAVE_DIAN_DATA';

//Begin Template
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const STATUS_STEP_TEMPLATE = 'STATUS_STEP_TEMPLATE';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_MODALITY_TYPES = 'GET_MODALITY_TYPES';
export const GET_TOWNS = 'GET_TOWNS';
export const LOADING_SAVE_TEMPLATE = 'LOADING_SAVE_TEMPLATE';

//Begin Config
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_STATE = 'GET_CITIES_STATE';
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES';
export const GET_REGIME_TYPES = 'GET_REGIME_TYPES';
export const GET_MENU = 'GET_MENU';
export const GET_COMPONENTS = 'GET_COMPONENTS';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_CITIES_COUNTRY = 'GET_CITIES_COUNTRY';
export const GET_MENU_COMPONENT = 'GET_MENU_COMPONENT';
export const GET_FREQUENT_QUESTIONS = 'GET_FREQUENT_QUESTIONS';
export const GET_REPORT_PROBLEM = 'GET_REPORT_PROBLEM';
export const GET_SECURITY_POLICY = 'GET_SECURITY_POLICY';
export const SEND_MAIL = 'SEND_MAIL';
export const GET_QUESTION_FILTER = 'GET_QUESTION_FILTER';
export const GET_REASON = 'GET_REASON';
export const SEND_MAIL_CONTACT = 'SEND_MAIL_CONTACT';
export const GET_MENU_MYACCOUNT = 'GET_MENU_MYACCOUNT';
export const GET_MENU_PAYROLL_BULKLOAD = 'GET_MENU_PAYROLL_BULKLOAD';
export const GET_INCOMES_EXPENSES = 'GET_INCOMES_EXPENSES';
export const GET_MENU_EMPLOYEES = 'GET_MENU_EMPLOYEES';
export const GET_WORKER_TYPES = 'GET_WORKER_TYPES';
export const GET_CONTRACT_TYPES = 'GET_CONTRACT_TYPES';
export const GET_PAYMENT_PERIOD = 'GET_PAYMENT_PERIOD';
export const GET_BANKS = 'GET_BANKS';
export const GET_RETIRE_CAUSES = 'GET_RETIRE_CAUSES';
export const GET_MENU_HELP = '[Menu] Get menu to help view';
//End Config

//Begin Article
export const GET_UNIT_MEASURE = 'GET_UNIT_MEASURE';
export const GET_CATEGORY = 'GET_CATEGORY';
export const SAVE_ARTICLE = 'SAVE_ARTICLE';
export const GET_ARTICLE = 'GET_ARTICLE';
export const LOAD_ARTICLE = 'LOAD_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const SUCCES_DELETE_ARTICLE = 'SUCCES_DELETE_ARTICLE';
export const LOAD_ARTICLES_IN_CART = 'LOAD_ARTICLES_IN_CART';
export const CLOSE_COLLAPSE_ARTICLE = 'CLOSE_COLLAPSE_ARTICLE';
export const SEND_INDEX_TAB = 'SEND_INDEX_TAB';
export const GET_FILTER_ARTICLE = 'GET_FILTER_ARTICLE';
export const GET_CATEGORY_ARTICLE = 'GET_CATEGORY_ARTICLE';
export const CLEAN_ARTICLE = 'CLEAN_ARTICLE';
export const END_SEACRH_ARTICLE = 'END_SEACRH_ARTICLE';
export const CLEAN_REQUEST = 'CLEAN_REQUEST';
export const LOADING_GET_ARTICLES = 'LOADING_GET_ARTICLES';
export const GET_ARTICLE_LIST = 'GET_ARTICLE_LIST';
export const GET_ARTICLE_CHECKED = 'GET_ARTICLE_CHECKED';
export const LOADING_UPDATE_ARTICLE = 'LOADING_UPDATE_ARTICLE';
export const LOADING_SAVE_ARTICLE = 'LOADING_SAVE_ARTICLE';
export const UPDATE_FAVORITE_ARTICLE = 'UPDATE_FAVORITE_ARTICLE';
export const SAVE_ADD_ARTICLE = 'SAVE_ADD_ARTICLE';

//Begin Category
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const LOAD_CATEGORY = 'LOAD_CATEGORY';
export const CLEAN_DATA_CATEGORY = 'CLEAN_DATA_CATEGORY';
export const CLEAN_STATUS = 'CLEAN_STATUS';
export const LOADING_GET_CATEGORIES = 'LOADING_GET_CATEGORIES';

//Begin Favorite
export const SUCCES_CATEGORY_FAVORITE = 'SUCCES_CATEGORY_FAVORITE';
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE';

//Begin Invoice
export const GET_INVOICES = 'GET_INVOICES';
export const SUCESS_DOCUMENT_UPDATE = 'SUCESS_DOCUMENT_UPDATE';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const SUCESS_DOCUMENT_CREATE = 'SUCESS_DOCUMENT_CREATE';
export const INFO_DOCUMENT_CREATE = 'INFO_DOCUMENT_CREATE';
export const SUCESS_QUOTATION_CREATE = 'SUCESS_QUOTATION_CREATE';
export const RESEND_ELECTRONIC_INVOICE = 'RESEND_ELECTRONIC_INVOICE';
export const GET_ADITIONAL_DATA_INVOICE = 'GET_ADITIONAL_DATA_INVOICE';
export const SUCESS_SEND_INVOICE = 'SUCESS_SEND_INVOICE';
export const GET_ITEMS_CART = 'GET_ITEMS_CART';
export const SHOW_CATALOG_ITEMS = 'SHOW_CATALOG_ITEMS';
export const GET_TOTAL_DOCUMENTS = 'GET_TOTAL_DOCUMENTS';
export const CALCULE_INVOICE_VALUES = 'CALCULE_INVOICE_VALUES';
export const GET_QUOTATION = 'GET_QUOTATION';
export const GET_REASONS_NOTES = 'GET_REASONS_NOTES';
export const GET_FILE_INVOICE = 'GET_FILE_INVOICE';
export const ACTIVE_STEP_INVOICE = 'ACTIVE_STEP_INVOICE';
export const GET_INFORME = 'GET_INFORME';
export const SHOW_INVOICE_CREATION = 'SHOW_INVOICE_CREATION';
export const LOADING_GET_DOCUMENTS = 'LOADING_GET_DOCUMENTS';
export const GET_LIST_RETEFUENTE = 'GET_LIST_RETEFUENTE';
export const GET_LIST_RETEICA = 'GET_LIST_RETEICA';
export const GET_LIST_RETEIVA = 'GET_LIST_RETEIVA';
export const LOADING_GET_DETAIL_DOCUMENT = 'LOADING_GET_DETAIL_DOCUMENT';
export const LOADING_UPDATE_STATUS_DOCUMENT = 'LOADING_UPDATE_STATUS_DOCUMENT';
export const LOADING_UPDATE_FAVOURITE_DOCUMENT =
  'LOADING_UPDATE_FAVOURITE_DOCUMENT';
export const LOADING_UPDATE_FROM_LIST = 'LOADING_UPDATE_FROM_LIST';
export const LOADING_DELETE_DOCUMENT = 'LOADING_DELETE_DOCUMENT';

//End Invoice

//Begin Values Invoice
export const GET_DOCUMENT_DETAIL = 'GET_DOCUMENT_DETAIL';
export const GET_TOTAL_DETAIL = 'GET_TOTAL_DETAIL';
export const GET_CHARGES_DOCUMENT = 'GET_CHARGES_DOCUMENT';
export const LOAD_CHARGE_DOCUMENT = 'LOAD_CHARGE_DOCUMENT';
export const UPDATE_CHARGE_DOCUMENT = 'UPDATE_CHARGE_DOCUMENT';
export const OPEN_MODAL_CHARGE = 'OPEN_MODAL_CHARGE';
export const GET_DISCOUNTS_DOCUMENT = 'GET_DISCOUNTS_DOCUMENT';
export const LOAD_DISCOUNT_DOCUMENT = 'LOAD_DISCOUNT_DOCUMENT';
export const OPEN_MODAL_DISCOUNT = 'OPEN_MODAL_DISCOUNT';
export const UPDATE_DISCOUNT_DOCUMENT = 'UPDATE_DISCOUNT_DOCUMENT';
export const SHOW_CART_INVOICE = 'SHOW_CART_INVOICE';
export const DOCUMENT_VALID = 'DOCUMENT_VALID';
export const ROUND_VALUES_STATUS = 'ROUND_VALUES_STATUS';
//End Values Invoice

//Begin Client
export const SAVE_CLIENT = 'SAVE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_STATUS = 'UPDATE_CLIENT_STATUS';
export const GET_FILTERLIST_CLIENT = 'GET_FILTERLIST_CLIENT';
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_CLIENT_DETAIL = 'LOAD_CLIENT_DETAIL';
export const LOADING_SAVE_CLIENT = 'LOADING_SAVE_CLIENT';
export const LOADING_LIST_CLIENT = 'LOADING_LIST_CLIENT';
export const LOADING_UPDATE_CLIENT_STATUS = 'LOADING_UPDATE_CLIENT_STATUS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const LOADING_DELETE_CLIENT = 'LOADING_DELETE_CLIENT';
export const STANDOUT_CLIENT = 'STANDOUT_CLIENT';
export const LOADING_STANDOUT_CLIENT = 'LOADING_STANDOUT_CLIENT';
export const LOADING_FILTERLIST_CLIENT = 'LOADING_FILTERLIST_CLIENT';
export const LOADING_DETAIL_CLIENT = 'LOADING_DETAIL_CLIENT';
export const LOADING_GET_CLIENT = 'LOADING_GET_CLIENT';
export const GET_CLIENTS_RESUME_LIST = 'GET_CLIENTS_RESUME_LIST';
export const SELECTED_ID_CLIENT = 'SELECTED_ID_CLIENT';
export const LOADING_GET_EXISTING_CLIENT = 'LOADING_GET_EXISTING_CLIENT';
export const OPEN_REGISTER_EMPLOYEE = 'OPEN_REGISTER_EMPLOYEE';
export const OPEN_MASSIVE_LOADING_EMPLOYEE = 'OPEN_MASSIVE_LOADING_EMPLOYEE';

//End Client

//Begin Address
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const GET_ADDRESSLIST = 'GET_ADDRESSLIST';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
//End Address

//Begin contact
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const GET_CONTACTSLIST = 'GET_CONTACTSLIST';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const LOAD_CONTACT = 'LOAD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
//End contact

//Begin Charge
export const SAVE_CHARGE = 'SAVE_CHARGE';
export const UPDATE_CHARGE = 'UPDATE_CHARGE';
export const DELETE_CHARGE = 'DELETE_CHARGE';
export const GET_CHARGELIST = 'GET_CHARGELIST';
export const GET_CHARGE = 'GET_CHARGE';
export const GET_FILTER_CHARGES = 'GET_FILTER_CHARGES';
export const GET_FILTER_CHARGE = 'GET_FILTER_CHARGE';
export const LOADING_SAVE_CHARGE = 'LOADING_SAVE_CHARGE';
export const CHANGE_EDITING_CHARGE_STATUS = 'CHANGE_EDITING_CHARGE_STATUS';
export const LOADING_GET_CHARGES = 'LOADING_GET_CHARGES';
//End Charge

//Begin Dashboard
export const GET_LICENCE_DATA = 'GET_LICENCE_DATA';
export const GET_COUNTER_DATA = 'GET_COUNTER_DATA';
export const LOADING_LICENCE_DATA = 'LOADING_LICENCE_DATA';
export const LOADING_COUNTER_DATA = 'LOADING_COUNTER_DATA';
export const GET_INFO_CHARTS = 'GET_INFO_CHARTS';
export const LOADING_INFOCHARTS_DATA = 'LOADING_INFOCHARTS_DATA';
//End Dashboard

// Begin Incomes Expenses
export const SAVE_INCOME_EXPENSE = 'SAVE_INCOME_EXPENSE';
export const GET_INCEXP_SAVED = 'GET_INCEXP_SAVED';
// end Incomes Expenses

// Begin Incomes Payroll
export const GET_ALL_PAYROLL_REPORTED = 'GET_ALL_PAYROLL_REPORTED';
export const LOADING_PAYROLL_REPORTED = 'LOADING_PAYROLL_REPORTED';
export const GET_PAYROLL_REPORTED_DETAIL = 'GET_PAYROLL_REPORTED_DETAIL';
export const UPDATE_ALL_PAYROLL_EMPLOYEE = 'UPDATE_ALL_PAYROLL_EMPLOYEE';
export const UPDATE_PAYROLL_FAVORITE = 'UPDATE_PAYROLL_FAVORITE';
export const UPDATE_SUPPORT_PAYROLL_FAVORITE =
  'UPDATE_SUPPORT_PAYROLL_FAVORITE';
export const UPDATE_CONFIRM_PAYROLL = 'UPDATE_CONFIRM_PAYROLL';
export const UPDATE_PAYROLL_EMPLOYEE_SELECTED =
  'UPDATE_PAYROLL_EMPLOYEE_SELECTED';
export const FILL_ALL_PAYROLL_EMPLOYEE = 'FILL_ALL_PAYROLL_EMPLOYEE';
export const FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR =
  'FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR';
export const FILL_ALL_PAYROLL_SUPPORTS = 'FILL_ALL_PAYROLL_SUPPORTS';
export const LOADING_PAYROLL_SUPPORTS = 'LOADING_PAYROLL_SUPPORTS';
export const FILL_ALL_PAYROLL_EDITED = 'FILL_ALL_PAYROLL_EDITED';
export const FILL_PAYROLL_DETAIL_EMPLOYEE = 'FILL_PAYROLL_DETAIL_EMPLOYEE';
export const FILL_PAYROLL_SUPPORTS_EMPLOYEE = 'FILL_PAYROLL_SUPPORT_EMPLOYEE';
export const GET_DETAIL_PAYROLL = 'GET_DETAIL_PAYROLL';
export const FILL_DETAIL_EDITED = 'FILL_DETAIL_EDITED';
export const FILL_DETAIL_SUPPORTS = 'FILL_DETAIL_SUPPORTS';
export const FILL_DETAIL_PAYROLL = 'FILL_DETAIL_PAYROLL';
export const FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID =
  'FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID';
export const ADD_NEW_INCOME_PAYROLL = 'ADD_NEW_INCOME_PAYROLL';
export const UPDATE_INCOME_PAYROLL = 'UPDATE_INCOME_PAYROLL';
export const ADD_NEW_EXPENSE_PAYROLL = 'ADD_NEW_EXPENSE_PAYROLL';
export const UPDATE_EXPENSE_PAYROLL = 'UPDATE_EXPENSE_PAYROLL';
export const DELETE_EXPENSE_PAYROLL = 'DELETE_EXPENSE_PAYROLL';
export const DELETE_INCOME_PAYROLL = 'DELETE_INCOME_PAYROLL';
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const SET_FILES_PREVIEW = 'SET_FILES_PREVIEW';
export const RESET_PAYROLL_FORM = 'RESET_PAYROLL_FORM';
export const SET_TRANSMISION_PROCESS = 'SET_TRANSMISION_PROCESS';
export const SET_TRANSMISION_STATE = 'SET_TRANSMISION_STATE';
export const SEND_SUPPORT_PAYROLL = 'SEND_SUPPORT_PAYROLL';
export const RESET_PAYROLL_LIST = 'RESET_PAYROLL_LIST';
export const RESPONSE_TRANSMISION_STATE = 'RESPONSE_TRANSMISION_STATE';

export const LOADING_RETIRE_EMPLOYEE_PAYROLL =
  'LOADING_RETIRE_EMPLOYEE_PAYROLL';

export const UPDATE_EMPLOYEE_RETIREMENT = 'UPDATE_EMPLOYEE_RETIREMENT';

export const UPDATE_STATUS_TRANSMITION_SUPPORT =
  '[TRANSMITION SUPPORT] update status item in list support';

// end Incomes Payroll

//Begin Employee
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_ADITIONAL_INFO = 'GET_EMPLOYEES_ADITIONAL_INFO';
export const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE';
export const LOADING_GET_EMPLOYEES = 'LOADING_GET_EMPLOYEES';
export const LOADING_DETAIL_EMPLOYEE = 'LOADING_DETAIL_EMPLOYEE';
export const UPDATE_CHANGE_STATUS = 'UPDATE_CHANGE_STATUS';
export const UPDATE_FAVORITE_EMPLOYEE = 'UPDATE_FAVORITE_EMPLOYEE';
export const SELECTED_ID_EMPLOYEE = 'SELECTED_ID_EMPLOYEE';
export const LOAD_EMPLOYEE_DETAIL = 'LOAD_EMPLOYEE_DETAIL';
export const GET_EMPLOYEES_RESUME_LIST = 'GET_EMPLOYEES_RESUME_LIST';
export const CHANGE_EDITION_EMPLOYEE_STATE = 'CHANGE_EDITION_EMPLOYEE_STATE';
export const FILL_SUPPORT_EMPLOYEE_DETAIL = 'FILL_SUPPORT_EMPLOYEE_DETAIL';
export const RETIRE_EMPLOYEE = 'RETIRE_EMPLOYEE';
export const LOADING_RETIRE_EMPLOYEE = 'LOADING_RETIRE_EMPLOYEE';
export const SET_REINTEGRE_EMPLOYEE = 'SET_REINTEGRE_EMPLOYEE';

// Register Employee
export const SAVE_BASIC_DATA_EMPLOYEE = 'SAVE_BASIC_DATA_EMPLOYEE';
export const SAVE_CONTRACT_DATA_EMPLOYEE = 'SAVE_CONTRACT_DATA_EMPLOYEE';
export const SAVE_PAYMENT_DATA_EMPLOYEE = 'SAVE_PAYMENT_DATA_EMPLOYEE';
export const SAVE_NOVELTIES_DATA_EMPLOYEE = 'SAVE_NOVELTIES_DATA_EMPLOYEE';
export const SAVE_RESPONSE_CREATE_PROCESS = 'SAVE_RESPONSE_CREATE_PROCESS';
export const RESET_REGISTER_FORM = 'RESET_REGISTER_FORM';
export const ADD_NEW_INCOME_EMPLOYEE = 'ADD_NEW_INCOME_EMPLOYEE';
export const UPDATE_INCOME_EMPLOYEE = 'UPDATE_INCOME_EMPLOYEE';
export const ADD_NEW_EXPENSE_EMPLOYEE = 'ADD_NEW_EXPENSE_EMPLOYEE';
export const UPDATE_EXPENSE_EMPLOYEE = 'UPDATE_EXPENSE_EMPLOYEE';
export const DELETE_INCOME_EMPLOYEE = 'DELETE_INCOME_EMPLOYEE';
export const DELETE_EXPENSE_EMPLOYEE = 'DELETE_EXPENSE_EMPLOYEE';

//End Employee

export const types = {
  //BASIC DATA
  GET_NOMINA_CERTIFICATE: '[Basic Data] Get nomina enterprise certificate',

  // DASHBOARD
  GET_LICENSE_REFERENCE: '[Dashboard] Get licence reference',
  LOADING_LICENSE_REFERENCE: '[Dashboard] Loading licence reference',
  SET_LICENSE_VALIDATION: '[Dashboard] Set licence validation',
  UPDATE_LICENSE_EMPLOYEE: '[Dashboard] Update licence employee',
  LOADING_LICENSE_UPDATE: '[Dashboard] Loading licence update license',
  LOADING_LICENSE_VALIDITY: '[Dashboard] Loading licence validation',
  CLEAR_LICENSE_REFERENCE: '[Dashboard] Clear licence reference',

  // PAYROLL INTEGRATION
  FILL_INTEGRATION_EMPLOYEES: '[Integration] Fill list integration employees',
  LOADING_INTEGRATION_EMPLOYEES:
    '[Integration] Loading get employees integration',
  SET_EMPLOYEE_INTEGRATION: '[Integration] Set employee integration',
  FILL_INTEGRATION_DOCUMENTS:
    '[Integration] Fill list employee integration documents',
  LOADING_INTEGRATION_DOCUMENTS:
    '[Integration] Loading get employee integration documents',
  LOADING_FILES_INTEGRATION_DOCUMENTS:
    '[Integration] Loading get files to document',
  FILL_INTEGRATION_FILES: '[Integration] Fill files to integration',
  LOADING_SEND_INTEGRATION_DOCUMENTS:
    '[Integration] Loading send files to document',
  CLEAN_INTEGRATION_REDUCER: '[Integration] Clean integration reducer',

  // PAYROLL ADJUSTMENT
  ADD_INCOME_PAYROLL: '[PAYROLL] Add income to payroll',
  LOAD_INCOME_PAYROLL: '[PAYROLL] Load income to edit',
  UPDATE_INCOME_PAYROLL: '[PAYROLL] Update income to payroll',
  DELETE_INCOME_PAYROLL: '[PAYROLL] Delete income to payroll',
  FILL_INCOMES_PAYROLL: '[PAYROLL] Fill incomes payroll',
  CLEAN_INCOMES_REDUCER: '[PAYROLL] Clean income reducer',
  LOADING_ADD_INCOME: '[PAYROLL] Loading add income',
  LOADING_DELETE_INCOME: '[PAYROLL] Loading delete income',

  ADD_EXPENSE_PAYROLL: '[PAYROLL] Add expense to payroll',
  LOAD_EXPENSE_PAYROLL: '[PAYROLL] Load expense to edit',
  UPDATE_EXPENSE_PAYROLL: '[PAYROLL] Update expense to payroll',
  DELETE_EXPENSE_PAYROLL: '[PAYROLL] Delete expense to payroll',
  FILL_EXPENSES_PAYROLL: '[PAYROLL] Fill expenses payroll',
  CLEAN_EXPENSE_REDUCER: '[PAYROLL] Clean expense reducer',
  LOADING_ADD_EXPENSE: '[PAYROLL] Loading add expense',
  LOADING_DELETE_EXPENSE: '[PAYROLL] Loading delete expense',

  LOADING_GET_PAYROLL: '[PAYROLL] Loading get data payroll to edit',
  LOAD_PAYROLL_ADJUSTMENT: '[Adjustment] Load data payroll to edit',
  CLEAN_PAYROLL_ADJUSTMENT: '[Adjustment] Clean payroll adjustment data',
  SET_OBSERVATIONS_PAYROLL: '[PAYROLL] Set observations payroll',
  LOADING_ADD_PAYROLL_ADJUSTMENT:
    '[Adjustment] Loading register payroll adjustment',
  FILL_INCOMES_LIST: '[Payroll] Fill incomes list',
  FILL_EXPENSES_LIST: '[Payroll] Fill expenses list',

  SET_TYPE_LICENSE: '[PAYROLL] Set type license',
  SET_LICENSE_BASE: '[PAYROLL] Set license base',

  ADD_OBSERVATION_PAYROLL: '[PAYROLL] Add observation to payroll',
  LOADING_OBSERVATION: '[PAYROLL] Spinner update observation',

  //PAYROLL BY EMPLOYEE
  FILL_PAYROLL_HISTORY: '[PAYROLL] Fill payroll history by employee',
  LOADING_PAYROLL_HISTORY: '[PAYROLL] Loading get payroll history by employee',
  FILL_PAYROLL_FILES: '[PAYROLL] Fill payroll files',
  LOADING_PAYROLL_FILES: '[PAYROLL] Loading get payroll files',
  CLEAN_PAYROLL_FILES: '[PAYROLL] Clean payroll files',
  LOADING_PAYROLL_EMAIL: '[PAYROLL] Loading send payroll email',
  UPDATE_PAYROLL_HISTORY: '[PAYROLL] Update payroll history items',
  CLEAN_PAYROLL_HISTORY: '[PAYROLL] Clean payroll history reducer',

  //PAYROLL REPORT
  FILL_PAYROLL_REPORT: '[REPORT] Fill payroll to report',
  CHECK_ALL_PAYROLL: '[REPORT] check all payroll items',
  SET_FILTER_PAYROLL: '[REPORT] set data to filter payroll reported',
  LOADING_PAYROLL_REPORT: '[REPORT] Loading get payroll report list',
  ADD_CHECKED_PAYROLL: '[REPORT] Add checked payroll item',
  LOADING_GET_SUPPORT: '[REPORT] Loading support employee',
  FILL_SUPPORT_EMPLOYEE: '[REPORT] Fill detail support employee',
  CONFIRM_PAYROLL: '[REPORT] Confirm payroll item',
  LOADING_CONFIRM_PAYROLL: '[REPORT] Loading confirm payroll item',
  SET_DATE_INSTANCE: '[Report] Set date period',
  UPDATE_TOTAL_PAYROLL: '[REPORT] update payroll total',
  START_TRANSMISSION: '[REPORT] start transmission',
  LOADING_TRANSMISSION: '[REPORT] Loading transmission',
  UPDATE_STATUS_TRANSMISSION: '[REPORT] Update status transmission',
  UPDATE_CHECKED_LIST: '[REPORT] Update checked list',
  LOADING_PAY_DATE: '[REPORT] Loading update pay date',
  GET_TOTAL_DATA_PAYROLL: '[REPORT] Get payroll total',
  LOADING_TOTAL_PAYROLL: '[REPORT] Loading get total payroll',
  CLEAN_PAYROLL_REPORT: '[REPORT] Clean payroll report reducer',
  UPDATE_DATA_TRANSMISSION: '[REPORT] Update data transmission',
  SET_DATA_PAYROLL: '[REPORT] Set data payroll',
  SET_CHECKED_PAYROLL: '[REPORT] Set checked payroll list',
  GET_PAYROLL_PREVIEW: '[REPORT] Get payroll preview',
  LOADING_PAYROLL_PREVIEW: '[REPORT] Loading payroll preview',
  CLEAN_PAYROLL_PREVIEW: '[REPORT] Clean payroll preview reducer',

  //PAYROLL REPORTED
  GET_PAYROLL_REPORTED: '[PAYROLL] Get payroll reported reducer',
  LOADING_PAYROLL_REPORTED: '[PAYROLL] Loading payroll reported',
  FILTER_BY_DATES_REPORTED: '[PAYROLL] Filter by dates reported',
  GET_PAYROLL_DETAIL_REPOTED: '[PAYROLL] Get Payroll detail reported',
  SET_PAYROLL_FAVORITE_REPORTED: '[PAYROLL] Set Payroll Favorite reported',
  LOADING_SPINNER_PAYROLL_REPORTED: '[PAYROLL] Spinner payroll reported',
  LOADING_SPINNER_PAYROLL_REPORTED_DETAIL:
    '[PAYROLL] Spinner payroll reported Detail',

  //EMPLOYEE
  LOADING_CREATE_EMPLOYEE: '[EMPLOYEE] Spinner create new employee',

  //Bulkload
  GET_BULKLOAD_EMPLOYEE: '[BULKLOAD] Get bulkload employee',
  GET_BULKLOAD_ERROR_EMPLOYEE: '[BULKLOAD] Get bulkload error employee',
  GET_BULKLOAD_DATASET: '[BULKLOAD] Get bulkload dataset',
  CLEAN_BULKLOAD_EMPLOYEE: '[BULKLOAD] Clean bulkload employee',

  // BulkLoad Payroll
  GET_BULKLOAD_PAYROLL: '[BULKLOAD] Get bulkload payroll',
  GET_BULKLOAD_ERROR_PAYROLL: '[BULKLOAD] Get bulkload error payroll',
  GET_BULKLOAD_DATASET_PAYROLL: '[BULKLOAD] Get bulkload dataset payroll',
  GET_BULKLOAD_REPORTDOWNLOADED_PAYROLL:
    '[BULKLOAD] Get bulkload report downloaded payroll',
  GET_BULKLOAD_PAYROLL_RESPONSE: '[BULKLOAD] Get bulkload payroll response',
  CLEAN_BULKLOAD_PAYROLL: '[BULKLOAD] Clean bulkload payroll',
};
