import { Grid } from '@material-ui/core';
import deLocale from 'date-fns/locale/es';
import { format } from 'date-fns';
import React from 'react';

export const generateModalCertificate = (setState, data, style) => {
  const formatDate = (date, formatstr) => {
    if (!date) return '';

    return format(new Date(date), formatstr, {
      locale: deLocale,
    });
  };

  return setState({
    modalType: 'warning',
    title:
      'Tu certificado digital ' +
      (data.diasVencimiento === 0
        ? 'ha vencido'
        : 'vence en ' + data.diasVencimiento + ' día(s)'),
    body: (
      <>
        {data.estadoNuevoCertificado === 'SOL' ? (
          'No olvides que el representante legal debe actualizar la información y documentación solicitada a través del correo electrónico para la renovación del certificado digital. '
        ) : (
          <Grid container className={style}>
            <Grid item lg={12} xs={12}>
              {`Tu certificado digital ${
                data.diasVencimiento < 0 ? 'venció' : 'vence'
              } el ${formatDate(
                data.certificadoHasta,
                'dd/MM/yyyy',
              )}, es necesario renovarlo
                  para que puedas seguir emitiendo documentos.`}
            </Grid>
            {/* <Grid item lg={3} xs={12}>
                  <ButtonPrimary
                    text={'Continuar'}
                    onClick={() => handleOpenCertificateModal()}
                  />
                </Grid> */}
          </Grid>
        )}
      </>
    ),
    modalImage: 'warning',
    open: true,
    closeElement: 'Cerrar',
    onCloseElement: 0,
  });
};
