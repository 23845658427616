import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';

import {
  regexEmail,
  regexAlphaNumeric,
  regexPhone,
  regexText,
} from '../../../../helpers/customRegex.hepers';
import InputForm from '../../../../components/input/input.component';
import SwitchForm from '../../../../components/switch/switch.component.js';
import TooltipMessage from '../../../../components/tooltip/tootltip-message.component';
import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import ToggleButtons from '../../../../components/toggle/toggle.component';
import ResponseRedirectModal from '../../../../components/modal/responseRedirectModal.component';
import ResponseModal from '../../../../components/modal/responseModal.component';

import {
  saveBillerAction,
  updateBillerAction,
  loadBillerAction,
  updateOpenModalItem,
  cleanLicenseResponseUser,
} from '../../../../actions/userAction';
import { CLAIM_USERS } from '../../../../config/config';
import { SuccessAlert2, WarningAlert } from '../../../../helpers/alert.helpers';
import isEmpty from '../../../../utils/isEmpty';

class Biller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtBillerName: '',
        txtBillerLastName: '',
        txtBillerEmail: '',
        txtBillerPhone: '',
        chkBillerRol: true,
        chkBillerStatus: true,
        toogleRol: '1',
      },
      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0,
        showProgress: 0,
        textProgress: '',
        valueProgressBar: 0,
      },
      openModalItem: false,
      saveBillerResponse: {},
      updateBillerResponse: {},
      loadedBillerObject: {},
      editBillerStatus: false,
      branchOfficesList: [],
      filteredBillersList: [],
      components: [],
      loadingSaveBiller: false,
      branchOfficesOptions: [],
      menuMyAccount: {},
      modalLicense: false,
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.userReducer.saveBillerResponse &&
      nextProps.userReducer.saveBillerResponse !== state.saveBillerResponse
    )
      update.saveBillerResponse = nextProps.userReducer.saveBillerResponse;

    if (nextProps.userReducer.loadedBillerObject !== state.loadedBillerObject)
      update.loadedBillerObject = nextProps.userReducer.loadedBillerObject;

    if (
      !isEmpty(nextProps.userReducer.editBillerStatus) &&
      nextProps.userReducer.editBillerStatus !== state.editBillerStatus
    )
      update.editBillerStatus = nextProps.userReducer.editBillerStatus;

    if (
      !!nextProps.userReducer.updateBillerResponse &&
      nextProps.userReducer.updateBillerResponse !== state.updateBillerResponse
    )
      update.updateBillerResponse = nextProps.userReducer.updateBillerResponse;

    if (
      !!nextProps.configReducer.components &
      (nextProps.configReducer.components !== state.components)
    )
      update.components = nextProps.configReducer.components;

    if (
      !!nextProps.branchOfficeReducer.filteredBranchOfficesList &&
      nextProps.branchOfficeReducer.filteredBranchOfficesList !==
        state.branchOfficesList
    ) {
      update.branchOfficesList =
        nextProps.branchOfficeReducer.filteredBranchOfficesList?.data;
      update.branchOfficesOptions = nextProps.branchOfficeReducer.filteredBranchOfficesList?.data?.filter(
        (b) => b.idstate === 0,
      );
    }

    if (
      nextProps.userReducer.filteredBillersList !== state.filteredBillersList
    ) {
      update.filteredBillersList = nextProps.userReducer.filteredBillersList;
    }

    if (
      !isEmpty(nextProps.userReducer.loadingSaveBiller) &&
      nextProps.userReducer.loadingSaveBiller !== state.loadingSaveBiller
    )
      update.loadingSaveBiller = nextProps.userReducer.loadingSaveBiller;

    if (
      !!nextProps.menuReducer.menuMyAccount &&
      nextProps.menuReducer.menuMyAccount !== state.menuMyAccount
    )
      update.menuMyAccount = nextProps.menuReducer.menuMyAccount;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.licenseBiller?.result &&
      !this.props.licenseBiller.result.licenseActiveBase &&
      !this.state.modalLicense
    ) {
      if (prevState.modalLicense === this.state.modalLicense) {
        this.setState({
          ...this.state,
          modalLicense: true,
        });
      }
    }
    if (prevState.saveBillerResponse !== this.state.saveBillerResponse) {
      if (this.state.saveBillerResponse.statusCode === '201') {
        this.setState({
          responseModal: {
            type: 'success',
            title: this.state.menuMyAccount.titleCreateUser,
            subtitle: this.state.menuMyAccount.messageCreateUser,
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.menuMyAccount.messageBodyPopUser,
                }}
              ></div>
            ),
            modalImage: 'successUser',
            open: true,
            closeElement: this.state.menuMyAccount.messageSkipUser,
            onCloseElement: 1,
            showProgress: 1,
            textProgress: this.state.menuMyAccount.messageProgressUser,
            valueProgressBar: 75,
          },
        });
        //Limpiar formulario
        this.cleanFormBiller();
      } else if (this.state.saveBillerResponse.statusCode === '301') {
        WarningAlert(
          'Usuario existente',
          'Ya existe un usuario con este email',
        );
      }
    }

    if (prevState.updateBillerResponse !== this.state.updateBillerResponse) {
      if (this.state.updateBillerResponse.statusCode === '201') {
        SuccessAlert2(
          this.state.menuMyAccount.titlePopUpdateUser,
          this.state.menuMyAccount.messagePopUpdateUser.replace(
            '$nombreUsuario',
            this.state.updateBillerResponse.result.nombres +
              ' ' +
              this.state.updateBillerResponse.result.apellidos,
          ),
        );
        this.cleanFormBiller();
      }
    }

    if (prevState.loadedBillerObject !== this.state.loadedBillerObject) {
      if (!!this.state.loadedBillerObject) {
        this.loadInfo(this.state.loadedBillerObject);
      }
    }
  }

  componentWillUnmount() {
    this.props.loadBillerAction(null);
  }

  /**
   * Sincroniza nuevo valor de input o select con state.
   * @param {*} e Evento
   */
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Sincroniza nuevo valor de checbox con state.
   * @param {*} e Evento
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked,
      },
    }));
  };

  /**
   * Elimina caracteres invalidos para correo electronico
   * @param {*} e Evento
   */
  validateEmail(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexEmail;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Elimina caracteres inavlidos para formatado telefonico
   * @param {*} e Evento
   */
  validatePhone(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexPhone;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Elimina caracteres invalidos, solamente letras y numeros
   * @param {*} e Evento
   */
  validateAlphaNumeric(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexAlphaNumeric;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores solo texto
   * @param {*} e Evento
   */
  validateText(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexText;
    value = value.replace(regex, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Sincroniza valor toogle button con state
   * @param {*} event  Evento
   * @param {*} newAlignment  Nuevo valor
   */
  syncChangeRol = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleRol: !!newAlignment ? newAlignment : '1',
      },
    }));
  };

  onCloseModalItem = (e) => {
    this.props.updateOpenModalItem(false);
  };

  handleResponseModal = () => {
    this.setState({
      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: '',
        showProgress: '',
        textProgress: '',
        valueProgressBar: '',
      },
    });
  };

  handleCloseResponseModal = () => {
    this.props.cleanLicenseResponseUser();
    this.setState({
      ...this.state,
      modalLicense: false,
    });
  };

  /**
   * Captura y guarda o actualiza información de usuario facturador
   * @param {*} e
   */
  onSaveBiller = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (this.state.editBillerStatus) {
      const data = {
        ...this.state.loadedBillerObject,
        nombres: this.state.form.txtBillerName,
        apellidos: this.state.form.txtBillerLastName,
        telefono: this.state.form.txtBillerPhone,
        rolid: this.state.form.toogleRol === '1' ? 5 : 6,
        idstate: this.state.form.chkBillerStatus === true ? 0 : 1,
        rol: null,
        aplicacion: 'COLNOMINA',
      };
      this.props.updateBillerAction(data);
    } else {
      let user = this.state.filteredBillersList.find(
        (b) =>
          b.email.toLowerCase() ===
          this.state.form.txtBillerEmail.toLowerCase(),
      );
      if (user && Object.keys(user).length) {
        WarningAlert(
          'Usuario existente....!',
          `Ya se encuentra registrado un usuario con el correo ${this.state.form.txtBillerEmail} `,
        );
        return;
      }
      const data = {
        nombres: this.state.form.txtBillerName,
        apellidos: this.state.form.txtBillerLastName,
        email: this.state.form.txtBillerEmail,
        telefono: this.state.form.txtBillerPhone,
        rolid: this.state.form.toogleRol === '1' ? 5 : 6,
        idstate: this.state.form.chkBillerStatus === true ? 0 : 1,
        createdby: 1,
        modifiedby: 1,
        idowner: 1,
        aplicacion: 'COLNOMINA',
      };
      this.props.saveBillerAction(data);
    }
  };

  /**
   * Limpia información del formulario
   */
  cleanFormBiller = () => {
    this.setState({
      form: {
        txtBillerName: '',
        txtBillerLastName: '',
        txtBillerEmail: '',
        txtBillerPhone: '',
        toogleRol: '1',
        chkBillerStatus: true,
      },
    });
    this.validator.hideMessages();
    this.props.loadBillerAction(null);
  };

  /**
   * Carga información de facturador para edición
   * @param {object} data Información facturador
   */
  loadInfo = (data) => {
    this.setState({
      form: {
        txtBillerName: data.nombres,
        txtBillerLastName: data.apellidos,
        txtBillerEmail: data.email,
        txtBillerPhone: data.telefono,
        toogleRol: data.rolid === 5 ? '1' : '2',
        chkBillerStatus: data.idstate === 0 ? true : false,
      },
    });
  };

  render() {
    //Listado sucursales
    let branchOfficesOptions = this.state.branchOfficesOptions;
    if (this.state.editBillerStatus === true) {
      let loadedBillerObject = this.state.loadedBillerObject;
      let exist = branchOfficesOptions.find(
        (b) => b.id === loadedBillerObject.sucursalid,
      );
      if (exist === undefined) {
        let newOption = this.state.branchOfficesList.find(
          (b) => b.id === loadedBillerObject.sucursalid,
        );
        if (newOption !== undefined) {
          branchOfficesOptions.push(newOption);
        }
      }
    }

    branchOfficesOptions = branchOfficesOptions.map((branch) => {
      return { value: branch.id, text: branch.nombre };
    });

    return (
      <form onSubmit={(e) => this.onSaveBiller(e)} autoComplete="off">
        <div className="note-yellow">
          Nota: Estos usuarios van a poder iniciar sesión e interactuar con la
          plataforma.
        </div>
        <Grid container alignItems="center" spacing={3}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={'txtBillerName'}
              label={'Nombres *'}
              maxLength={100}
              value={this.state.form.txtBillerName}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateText(e);
              }}
              validator={this.validator}
              validateOptions={'required'}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={'txtBillerLastName'}
              label={'Apellidos *'}
              maxLength={100}
              value={this.state.form.txtBillerLastName}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateText(e);
              }}
              validator={this.validator}
              validateOptions={'required'}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name="txtBillerEmail"
              label={'Correo electrónico *'}
              disabled={this.state.editBillerStatus}
              maxLength={100} //Debe ser 100
              value={this.state.form.txtBillerEmail}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateEmail(e);
              }}
              validator={this.validator}
              validateOptions={'required|email'}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name="txtBillerPhone"
              label={'Teléfono *'}
              maxLength={15}
              value={this.state.form.txtBillerPhone}
              onChange={(e) => {
                this.syncChanges(e);
                this.validatePhone(e);
              }}
              validator={this.validator}
              validateOptions={'required|min:7|max:15'} // Debe ser 15
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ToggleButtons
              label={'Rol'}
              value={this.state.form.toogleRol}
              onChange={this.syncChangeRol}
              titleOne={'Administrador'}
              titleTwo={'Colaborador'}
              txtTooltip={
                <TooltipMessage
                  title={this.state.menuMyAccount.titleTypeRole}
                  message={this.state.menuMyAccount.messageTypeRole}
                  botton={this.state.menuMyAccount.bottonTypeRole}
                  href={this.state.menuMyAccount.urlTypeRole}
                />
              }
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SwitchForm
              name="chkBillerStatus"
              titleOn={'Activar/Desactivar Usuario'}
              checked={this.state.form.chkBillerStatus}
              value={this.state.form.chkBillerStatus}
              onChange={this.syncCheckChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="flex-end" alignItems="center">
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ButtonPrimary
              text={this.state.menuMyAccount.buttonAddUser}
              loading={this.state.loadingSaveBiller}
              type={'submit'}
              disabled={this.props.disabledActions}
            />
          </Grid>
        </Grid>
        <ResponseRedirectModal
          modalType={'success'}
          title={'Upps!!!'}
          subtitle={
            'Ya usaste todos los usuarios disponibles de tu plan. No te preocupes, consigue todos los que necesites'
          }
          body={''}
          modalImage={'package'}
          open={this.props.modal}
          closeElement={'Skip'}
          onCloseElement={this.onCloseModalItem}
          claim={CLAIM_USERS}
        ></ResponseRedirectModal>
        <ResponseModal
          modalType={this.state.responseModal.modalType}
          title={this.state.responseModal.title}
          subtitle={this.state.responseModal.subtitle}
          body={this.state.responseModal.body}
          modalImage={this.state.responseModal.modalImage}
          open={this.state.responseModal.open}
          closeElement={this.state.responseModal.closeElement}
          onCloseElement={() => this.handleResponseModal()}
          showProgress={this.state.responseModal.showProgress}
          textProgress={this.state.responseModal.textProgress}
          valueProgressBar={this.state.responseModal.valueProgressBar}
        />
        <ResponseModal
          modalType={'warning'}
          title={'Tu plan de Colfactura nómina venció'}
          body={
            <div>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 25,
                }}
              >
                {
                  'No cuentas con un plan activo, es necesario renovarlo para que puedas seguir creando usuarios. '
                }
              </p>
              <p
                style={{
                  marginTop: 10,
                  fontWeight: 700,
                  fontSize: 25,
                }}
              >
                {'Si quieres comprar tu nuevo plan, haz clic en Comprar'}
              </p>
            </div>
          }
          modalImage={'warning'}
          open={this.state.modalLicense}
          textButton={'Comprar'}
          closeElement={'Skip'}
          onCloseElement={this.handleCloseResponseModal}
          urlButton={'https://colfactura.com/nomina-electronica/'}
        />
      </form>
    );
  }
}

Biller.propTypes = {
  saveBillerAction: PropTypes.func.isRequired,
  updateBillerAction: PropTypes.func.isRequired,
  loadBillerAction: PropTypes.func.isRequired,
  cleanLicenseResponseUser: PropTypes.func.isRequired,
  userReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  branchOfficeReducer: state.branchOfficeReducer,
  userReducer: state.userReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
  modal: state.userReducer.openModalItem,
  menuReducer: state.menuReducer,
  disabledActions: state.userReducer.disabledActions,
  licenseBiller: state.userReducer.licenseBiller,
});

export default connect(mapStateToProps, {
  saveBillerAction,
  updateBillerAction,
  loadBillerAction,
  updateOpenModalItem,
  cleanLicenseResponseUser,
})(Biller);
